import React, { useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql } from 'gatsby';

import useLocale from '../../hooks/useLocale';

import Link from '/src/components/LocalizedLink';
import Layout from '../../components/Layout';
import ForthcomingCourses from '../../components/ForthcomingCourses';
// import AplicationProcess from '../../components/AplicationProcess';
import ReviewsSection from '../../components/ReviewsSection';
import InternalSectionWithImage from '../../components/InternalSectionWithImage';
import Faq from '../../components/Faq';

import { URL_KEYS_ROUTES_EN, URL_ROUTES_EN } from '../../constants/routes';

import IconArrowRight from '../../assets/images/svg/arrow-right.inline.svg';
import IconArrowRightRed from '../../assets/images/svg/arrow-right-red.inline.svg';
import iconCalendar from '../../assets/images/svg/calendar.svg';
import iconClock from '../../assets/images/svg/clock.svg';
import iconColumn from '../../assets/images/trinity-cert-tesol-course-page/column.svg';
import iconTick from '../../assets/images/svg/tick.svg';
import iconAccomodation from '../../assets/images/student-support/Accommodation (1).svg';
import iconStudentVisa from '../../assets/images/student-support/Student visas (1).svg';
import iconCulture from '../../assets/images/student-support/Culture.svg';
import iconBureaucracy from '../../assets/images/student-support/Bureaucracy.svg';

import imgBenefit1 from '../../assets/images/trinity-cert-tesol-course-page/Internationally Recognized Qualification (1).svg';
import imgBenefit2 from '../../assets/images/trinity-cert-tesol-course-page/Practical Classroom Experience .svg';
import imgBenefit3 from '../../assets/images/trinity-cert-tesol-course-page/Small Group Sizes  (1).svg';
import imgBenefit5 from '../../assets/images/trinity-cert-tesol-course-page/Post-Grad Job Prospects 2.svg';
import imgReview from '../../assets/images/online-trinity-course/Снимок экрана 2020-11-05 212101.png';
import iconQuotes from '../../assets/images/trinity-cert-tesol-course-page/quotes.svg';
import imgDots from '../../assets/images/trinity-cert-tesol-course-page/Dots.svg';
import imgDevelopingTeacherCourse from '../../assets/images/other-popular-courses/Developing Teacher Course.png';
import imgTeachYoungLearners from '../../assets/images/other-popular-courses/Teacher Young Learners (1).png';
// import imgIntensiveSpanish from '../../assets/images/other-popular-courses/Intensive Spanish Course (1).png';
import imgBigImage from '../../assets/images/intensive-spanish/BigImage.png';
import imgFeedBack from '../../assets/images/intensive-spanish/FeedBack.png';

// import fileTrinityCertTESOLSyllabus from '../../assets/files/CertTESOL-syllabus-Jan-2016.pdf';
import fileAssesmentCriteria from '../../assets/files/Trinity CertTESOL assessment guidelines.pdf';

import '../../assets/styles/pages/intensive-spanish.scss';
import CourseSchedule from '../../components/CourseSchedule';

// const assesmentCriteria = `Download full assesment criteria <a href='${fileAssesmentCriteria}' target="_blank" class="red-link">(pdf)</a>`;
// const esAssesmentCriteria = `Descarga los criterios de evaluación completos <a href='${fileAssesmentCriteria}' target="_blank" class="red-link">(pdf)</a>`;

// const cardData = [
//   {
//     course: 'Trinity CertTESOL',
//     from: '5 October 2020',
//     to: '30 October 2020',
//     price: '€1900',
//   },
// ];

const faq1 = `When does the course start?`;
const faq1Answer = `We start new courses Every Monday.`;
const faq2 = `What is the course timetable?`;
const faq2Answer = `09.30 - 13.30, Monday to Friday.`;
const faq3 = `What happens if the level of the group is too hard / easy for me?`;
const faq3Answer = `If you find that the level of the group is too hard for you, don't worry! Our instructors are experienced in accommodating different levels of learners. They will provide additional support, explanations, and resources to help you catch up and progress at your own pace. If you prefer you can also move down a level. <br/><br/> If you feel that the level is too easy for you, you can discuss with your instructor to explore options for moving up to a more challenging group.`;
const faq4 = `Who are my classmates? `;
const faq4Answer = `Your classmates may come from different backgrounds, countries, and age groups, creating a diverse learning environment. Interacting with your classmates will provide you with opportunities to practise your Spanish skills and learn from each other's experiences.`;
const faq5 = `How quickly can I move up a level?`;
const faq5Answer = `The pace at which you can move up a level will depend on your individual progress and the course structure. Our instructors will assess your language skills regularly and provide feedback on your progress. 
<br/><br/>
Generally, if you are a beginner and study 20 hours/week, you will move up a level every 4-6 weeks. Once you achieve B2 level, it can take longer to achieve C1 (24 weeks approximately), then another 24 weeks to achieve C2. 
<br/><br/>
It's important to remember that language learning is a gradual process, and it's essential to have a solid foundation before moving on to more advanced levels.
`;
const faq6 = `Can I get a certificate of completion?`;
const faq6Answer = `Yes, upon successfully completing the Spanish course, you will be eligible to receive a certificate of completion. The certificate will verify your participation in the course and indicate the level of proficiency you have achieved. It can be a valuable addition to your resume or used to showcase your language skills to potential employers or educational institutions. Please note that specific criteria, such as attendance and performance, may need to be met to qualify for the certificate.`;

const esFaq1 = `¿Este curso me califica para enseñar inglés?`;
const esFaq1Answer = `Sí. El certificado Trinity CertTESOL es uno de los pocos cursos reconocidos por gobiernos y escuelas internacionalmente.`;
const esFaq2 = `¿Cuánto voy a ganar como profesor/a de inglés?`;
const esFaq2Answer = `La tarifa media de pago hoy en día para nuevos profesores es de 15-17€/hora. Trabajando para una academia a tiempo completo (20 horas), puedes esperar ganar 1300€/mes después de impuestos. Si añades 2 clases privadas por semana (es fácil hacer más), puedes aumentar esto a 1600€/mes.`;
const esFaq3 = `¿Necesito hablar español para enseñar inglés en España?`;
const esFaq3Answer = `No. El inglés es el único idioma utilizado en el aula y queremos mostrarte cómo puedes enseñar sin usar una sola palabra del idioma extranjero.`;
const esFaq4 = `¿Cuánto tiempo me llevará encontrar un trabajo?`;
const esFaq4Answer = `Normalmente dos semanas de búsqueda continua de trabajo. Ejecutamos nuestro taller de búsqueda de empleo al comienzo del curso para que puedas comenzar a buscar trabajo antes de que finalice el curso.`;
const esFaq5 = `¿Necesito una visa?`;
const esFaq5Answer = `Si eres ciudadano de la Unión Europea, eres libre de trabajar y estudiar en cualquiera de los países de la UE. Si no eres ciudadano de la UE, puedes permanecer hasta tres meses como turista y eres libre de estudiar y hacer clases privadas durante este tiempo. Si deseas quedarte más tiempo y obtener un permiso de trabajo, la forma más fácil de hacerlo es con una visa de estudiante, que te permitirá trabajar hasta 20 horas por semana (que es tiempo completo para un profesor de inglés). TEFL Iberia puede procesar una visa de estudiante para ti, para obtener información más detallada, por favor contacta classes@ilabarcelona.com.`;
const esFaq6 = `¿Cuál es el costo promedio de vida en España?`;
const esFaq6Answer = `En Barcelona puedes esperar pagar 550-600€ por una habitación doble de calidad en un piso compartido en el centro de la ciudad, y luego unos 150€/semana en comida y ocio - este dinero se estira bastante ya que Barcelona puede ser muy barata en comparación con el Reino Unido / EE. UU. / Canadá / Australia.
<br /><br />Sevilla es aún más barata: una habitación doble en un piso compartido costará alrededor de 350€ y puedes esperar gastar alrededor de 120€/semana en comida y entretenimiento.
`;

const ActiveForthcomingCourses = ({ courseType, staticCourseTypeName, allCourses }) => {
  const intl = useIntl();
  const [isMore, setIsMore] = useState(false);

  const showLessDates =
    intl.formatMessage({ id: 'forthcomingCourses.active.showLess' }) || 'Show Less Dates';
  const lodaMoreDates =
    intl.formatMessage({ id: 'forthcomingCourses.active.loadMore' }) || 'Load More Dates';

  return (
    <div className={`inner-wrapper ${courseType === staticCourseTypeName ? 'active' : ''}`}>
      <ForthcomingCourses cardData={allCourses.slice(0, isMore ? allCourses.length : 4)} />
      {allCourses.length > 4 ? (
        <button
          onClick={() => setIsMore(!isMore)}
          className="c-btn c-btn--red-border load-more-dates"
        >
          {isMore ? showLessDates : lodaMoreDates}
          <IconArrowRightRed />
        </button>
      ) : null}
    </div>
  );
};

const TrinityCertTesolCourse = ({ data }) => {
  const allPrismicCourses = data.allPrismicCourses.edges;

  const { enLocale } = useLocale({ matchPathName: '/es/' });
  return (
    <Layout
      title={'Intensive Spanish Course in Barcelona | 20h / Week'}
      description={
        "Achieve your goals with the best intensive Spanish courses in Barcelona. You'll enjoy small groups, affordable prices, and expert student visa support."
      }
    >
      <div className="intensive-spanish-course-page">
        <section className="c-first-section c-first-section--sm">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-9 col-xl-7">
                <div className="c-first-section__schedule">
                  <div className="item__wrapper">
                    <img src={iconCalendar} alt="calendar" />
                    <span className="c-text-14">2–52 weeks</span>
                  </div>
                  <div className="item__wrapper">
                    <img src={iconClock} alt="clock" />
                    <span className="c-text-14"> 09.30-13.30</span>
                  </div>
                  <div className="item__wrapper">
                    <img src={iconColumn} alt="column" />
                    <span className="c-text-14"> 20 hours/week</span>
                  </div>
                </div>
                <h1 className="c-first-section__title">Intensive Spanish</h1>
                <p className="c-first-section__description">
                  Move up a level in just 4 weeks with our Intensive course.
                </p>
                <div className="c-first-section__btns">
                  <FormattedMessage id={URL_KEYS_ROUTES_EN.APPLY}>
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--red">
                        Sign up now
                        <IconArrowRight />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="course-schedule-section">
          <div className="container">
            <div className="course-schedule__wrapper">
              <div className="c-title-13  schedule-title">Course Schedule & Levels</div>
              <div className="schedule-wrapper">
                <div className="schedule">
                  <img src={iconLevels} alt="Levels" />
                  <span>Levels:</span> <b>A1 - C1</b>
                </div>{' '}
                <div className="schedule">
                  <img src={iconCalendar} alt="calendar" />
                  <span>Start dates:</span> <b>Every Monday</b>
                </div>
                <div className="schedule">
                  <img src={iconClock} alt="clock" />
                  <span>Schedule:</span>
                  <b>09.30 - 13.30, Monday to Friday</b>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="second-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="c-title-34 second-section__l">
                  Give Yourself A Head start in Spanish
                </h2>
                <p className="c-text-18 description second-section__l">
                  Doing the intensive Spanish course will give you the best results in a short
                  amount of time. You’ll study 4 hours per day at the school, then have every
                  afternoon to put all your new learning into practice. The classes are 4 x 55 min
                  classes, with a short break to keep you focussed.
                </p>
                <div className="c-benefit-list second-section__l">
                  <h4 className="c-text-16">This course is ideal for you if:</h4>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">You want to make lots of progress quickly.</p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      You want to qualify for a student visa (if you study for 3-12 months).
                    </p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">You enjoy studying in small groups.</p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">You enjoy meeting people from all over the world</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wrapper">
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit1} alt="Small Groups" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18"> Small Groups</h3>
                      <p className="c-text-14">
                        We strictly limit the groups to 8, giving you maximum time with the teacher
                        and plenty of time to practise talking.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit2} alt="Excellent value" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Excellent value</h3>
                      <p className="c-text-14">
                        Learning Spanish doesn’t have to be expensive. We believe in accessible
                        education for all, and make our courses as affordable as possible.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit3} alt="Focus on Speaking" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Focus on Speaking</h3>
                      <p className="c-text-14">
                        Our teachers understand that you want to speak and express yourself in
                        Spanish, and we put a big emphasis on this in every class.
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit5} alt="Fun and varied classes" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">Fun and varied classes</h3>
                      <p className="c-text-14">
                        Build your vocabulary and fluency in no time, with fun and engaging classes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-section-includes c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="block_l">
                  <div className="block_l__img">
                    <img src={imgFeedBack} alt="Abigail Richter" className="img-main" />
                    <img src={imgDots} alt="dots" className="dots" />
                  </div>
                  <div className="block_l__text">
                    <img src={iconQuotes} alt="quotes" />
                    <p>
                      I’m so glad I decided to study at ILA. The Spanish classes were outstanding,
                      with enthusiastic teachers who made learning enjoyable and effective. The
                      support outside of the classroom was exceptional, including visa assistance
                      and help finding a room. I really feel confident in speaking Spanish and have
                      immersed myself in Barcelona's vibrant culture. It’s been a great experience
                      and I highly recommend them!
                      <br />
                      <strong>Abigail Richter</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="block_r">
                  <h2 className="c-title-34">What’s Included?</h2>
                  <div className="c-benefit-list block_r__benefits">
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        20 hours per week of classes with a qualified teacher.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">All necessary course & learning material.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">A dedicated course coordinator</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Access to our beautiful library, high-speed wifi, computers and learning
                        resources.
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        Access to our cultural programme (activities are paid separately)
                      </p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Expert guidance on student visas, if required.</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Social calendar at the school</p>
                    </div>
                    <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">Attendance certificate</p>
                    </div>
                    {/* <div className="benefit">
                      <img src={iconTick} alt="iconTick" />
                      <p className="c-text-16">
                        <FormattedMessage id="trinityCerttesol.sec3.benefit9" />
                      </p>
                    </div> */}
                  </div>
                  <div className="block_r__price block_r__price--full">
                    <h3 className="c-text-13">
                      <FormattedMessage id="trinityCerttesol.sec3.fullPrice" />
                    </h3>
                    <h3 className="c-title-32">
                      580€ <span className="lowercase">/month</span>
                    </h3>
                  </div>
                  <div className="block_r__price block_r__price--early-bird">
                    <h3 className="c-text-13">DISCOUNT PRICE</h3>
                    <h3 className="c-title-32">
                      1560€ <span className="lowercase"> for 3 months</span>
                    </h3>
                  </div>
                  <div className="block_r__price block_r__price--early-bird">
                    <h3 className="c-text-13">
                      1-YEAR VISA
                      <span className="notion">
                        See our{' '}
                        <FormattedMessage id={URL_KEYS_ROUTES_EN.COURSES_DATES}>
                          {(locale) => (
                            <Link to={locale[0]} className="colored no-link">
                              pricing page
                            </Link>
                          )}
                        </FormattedMessage>{' '}
                        for more course options.
                      </span>
                    </h3>
                    <h3 className="c-title-32">
                      3900€ <span className="lowercase"> for 1 year</span>
                    </h3>
                  </div>
                  <div className="block_r__btns">
                    <FormattedMessage id={URL_KEYS_ROUTES_EN.APPLY}>
                      {(locale) => (
                        <Link to={locale[0]} className="c-btn c-btn--red">
                          Book Now
                          <IconArrowRight />
                        </Link>
                      )}
                    </FormattedMessage>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={'https://form.jotform.com/231496158857369'}
                      className="c-btn c-btn--red-border"
                    >
                      Test Your Spanish
                      <IconArrowRightRed />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fourth-section courses-section c-section-py-70">
          <div className="container">
            <h1 className="courses-section__title c-title-34">Course Schedule & Levels</h1>
            {/* <p className="courses-section__description c-title-24">
              Courses Every Monday (I’d like to publish individual dates) <br />
              <br />
              <b>Timetable:</b> 09.30-13.30, Monday-Friday.
            </p> */}

            <CourseSchedule
              levels={() => (
                <ul>
                  <li>
                    <b>A1 - C1</b>
                  </li>
                </ul>
              )}
              startDates="Every Monday"
              schedule={() => <b>09.30 - 13.30, Monday to Friday</b>}
            />
            <div className="col text-center mt-5">
              <Link to={URL_ROUTES_EN.APPLY} className="c-btn c-btn--red">
                Book now
                <IconArrowRight />
              </Link>
            </div>
            {/* <div className="courses-section__btns">
              <button
                onClick={() => setCourseType('barcelona')}
                className={`btn ${courseType === 'barcelona' ? 'btn--active' : ''}`}
              >
                Barcelona
              </button>
              <button
                onClick={() => setCourseType('seville')}
                className={`btn ${courseType === 'seville' ? 'btn--active' : ''}`}
              >
                Seville
              </button>
            </div>
            <ActiveForthcomingCourses
              courseType={courseType}
              staticCourseTypeName={'barcelona'}
              allCourses={allBarcelonaCourses}
            />
            <ActiveForthcomingCourses
              courseType={courseType}
              staticCourseTypeName={'seville'}
              allCourses={allSevilleCourses}
            /> */}
          </div>
        </section>

        <section className="fifth-section c-student-support-section">
          <div className="container">
            <h2 className="c-title-34">
              <FormattedMessage id="studentSupport.title" />
            </h2>
            {/* <p className="c-text-18">
              <FormattedMessage id="studentSupport.description" />
            </p> */}
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconAccomodation} alt="accommodation" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.accommodation" />
                  </h3>
                  <FormattedMessage id={URL_KEYS_ROUTES_EN.ACCOMODATION}>
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconStudentVisa} alt="studentVisa" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.studentVisa" />
                  </h3>
                  <FormattedMessage id={URL_KEYS_ROUTES_EN.STUDENT_VISAS}>
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="item">
                  <img src={iconCulture} alt="culture" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.culture" />
                  </h3>
                  <FormattedMessage id={URL_KEYS_ROUTES_EN.CULTURAL_ACTIVITIES}>
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <img src={imgDots} className="dots" alt="dots" />
                <div className="item">
                  <img src={iconBureaucracy} alt="bureaucracy" />
                  <h3 className="c-title-22">
                    <FormattedMessage id="studentSupport.bureaucracy" />
                  </h3>
                  <FormattedMessage id="url.working-as-a-teacher.student-visas">
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--white">
                        <FormattedMessage id="homepage.sec7.i.findOutMore" />
                        <IconArrowRightRed />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sixth-section c-section-py">
          <InternalSectionWithImage
            imgSrc={imgBigImage}
            title="Learn Spanish in Spain’s most exciting city"
          />
          {/* <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="sixth-section__title c-title-34">
                  Learn Spanish in Spain’s most exciting city
                </h2>
                <div className="wrapper">
                  <img
                    className="big-img"
                    src={imgBigImage}
                    alt="Learn Spanish in Spain’s most exciting city"
                  />
                  <FormattedMessage id={URL_KEYS_ROUTES_EN.APPLY}>
                    {(locale) => (
                      <Link to={locale[0]} className="c-btn c-btn--red">
                        Book your course now
                        <IconArrowRight />
                      </Link>
                    )}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div> */}
        </section>

        {/* <section className="seventh-section">
          <div className="container">
            <h2 className="c-title-38">
              <FormattedMessage
                id="trinityCerttesol.sec7.title"
                values={{
                  accent: (chunk) => <span className="colored no-link">{chunk}</span>,
                }}
              />
            </h2>

            <FormattedMessage id="url.apply.index">
              {(locale) => (
                <Link to={locale[0]} className="c-btn c-btn--red">
                  <FormattedMessage id="sform.signUpNow" />
                  <IconArrowRight />
                </Link>
              )}
            </FormattedMessage>
          </div>
        </section> */}
        {/* 
        <section className="eigth-section c-faqs-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <h3 className="c-title-34">
                  <FormattedMessage id="trinityCerttesol.sec8.title" />
                </h3>
                <p className="c-text-18">
                  <FormattedMessage
                    id="trinityCerttesol.sec8.description"
                    values={{
                      accent: (chunk) => <span className="text-fw-600">{chunk}</span>,
                    }}
                  />
                </p>

                <div className="assesment-block">
                  <h4 className="c-title-20">
                    <FormattedMessage id="trinityCerttesol.sec8.notification.title" />
                  </h4>
                  <p className="c-text-16">
                    <FormattedMessage id="trinityCerttesol.sec8.notification.description" />
                  </p>
                  <a
                    href="https://www.tryinteract.com/share/quiz/5fa530f1849de80014ec5fbc"
                    className="c-btn c-btn--red-border"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="btn.takeEnglisAssessment" />
                    <IconArrowRightRed />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <Faq
                  title={enLocale ? 'Week 1' : 'Semana 1'}
                  description={enLocale ? week1 : esWeek1}
                />
                <Faq
                  title={enLocale ? 'Week 2' : 'Semana 2'}
                  description={enLocale ? week2 : esWeek2}
                />
                <Faq
                  title={enLocale ? 'Week 3' : 'Semana 3'}
                  description={enLocale ? week3 : esWeek3}
                />
                <Faq
                  title={enLocale ? 'Week 4' : 'Semana 4'}
                  description={enLocale ? week4 : esWeek4}
                />
                <Faq
                  title={enLocale ? 'Assesment Criteria' : 'Criterios de evaluación'}
                  description={enLocale ? assesmentCriteria : esAssesmentCriteria}
                />
                <a
                  target="_blank"
                  href={fileTrinityCertTESOLSyllabus}
                  className="c-btn c-btn--red mt-50"
                >
                  <FormattedMessage id="btn.downloadTrinitySyllabus" />
                </a>
              </div>
            </div>
          </div>
        </section> */}

        <section className="nineth-section">
          <ReviewsSection
            hideAllReviews
            description="Check out our latest reviews"
            title="We’ve Helped Hundreds of Students Learn Spanish and Set Up A Life In Barcelona. Here’s What They Have To Say:"
          />
        </section>

        <section className="tenth-section c-faqs-section">
          <div className="container">
            <div className="c-faqs-section__title">
              <h2 className="c-title-34">Frequently Asked Questions</h2>
              {/* <FormattedMessage id={URL_KEYS_ROUTES_EN.FAQ}>
                {(locale) => (
                  <Link to={locale[0]} className="c-btn c-btn--white">
                    <FormattedMessage id="btn.viewMoreFaqs" />
                    <IconArrowRightRed />
                  </Link>
                )}
              </FormattedMessage> */}
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Faq
                  title={enLocale ? faq1 : esFaq1}
                  description={enLocale ? faq1Answer : esFaq1Answer}
                />
                <Faq
                  title={enLocale ? faq2 : esFaq2}
                  description={enLocale ? faq2Answer : esFaq2Answer}
                />
                <Faq
                  title={enLocale ? faq3 : esFaq3}
                  description={enLocale ? faq3Answer : esFaq3Answer}
                />
              </div>
              <div className="col-lg-6">
                <Faq
                  title={enLocale ? faq4 : esFaq4}
                  description={enLocale ? faq4Answer : esFaq4Answer}
                />
                <Faq
                  title={enLocale ? faq5 : esFaq5}
                  description={enLocale ? faq5Answer : esFaq5Answer}
                />
                <Faq
                  title={enLocale ? faq6 : esFaq6}
                  description={enLocale ? faq6Answer : esFaq6Answer}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="eleventh-section c-other-courses-section">
          <div className="container">
            <h2 className="c-title-34">
              <FormattedMessage
                id="trinityCerttesol.sec11.title"
                values={{
                  accent: (chunk) => <span className="colored no-link">{chunk}</span>,
                }}
              />
            </h2>
            {/* <p className="c-text-18">
              <FormattedMessage id="trinityCerttesol.sec11.description" />
            </p> */}
            <div className="row">
              <div className="col-sm-6 col-lg-6">
                <FormattedMessage id={URL_KEYS_ROUTES_EN.SEMI_INTENSIVE_SPANISH}>
                  {(locale) => (
                    <Link to={locale[0]} className="item">
                      <img
                        src={imgDevelopingTeacherCourse}
                        alt="Developing Teacher Course"
                        className="item__img"
                      />
                      <p className="c-text-14">
                        <span className="item__price">10 hours </span>
                        /week
                        <br />
                        <span className="item__price">320€ </span>
                        /month
                      </p>
                      <p className="c-text-20">Semi-Intensive Spanish</p>
                    </Link>
                  )}
                </FormattedMessage>
              </div>
              <div className="col-sm-6 col-lg-6">
                <FormattedMessage id={URL_KEYS_ROUTES_EN.EXTENSIVE_SPANISH}>
                  {(locale) => (
                    <Link to={locale[0]} className="item">
                      <img
                        src={imgTeachYoungLearners}
                        alt="Teaching Young Learners"
                        className="item__img"
                      />
                      <p className="c-text-14">
                        <span className="item__price">4 hours </span>
                        /week <br />
                        <span className="item__price">360€ </span>
                        /3 months
                      </p>
                      <p className="c-text-20">Part-Time Spanish</p>
                    </Link>
                  )}
                </FormattedMessage>
              </div>
              {/* <div className="col-sm-6 col-lg-4">
                <FormattedMessage id="url.tefl-spanish-course-barcelona">
                  {(locale) => (
                    <Link to={locale[0]} className="item">
                      <img
                        src={imgIntensiveSpanish}
                        alt="imgIntensiveSpanish"
                        className="item__img"
                      />
                      <p className="c-text-14">
                        <span className="item__price">300€ </span>
                        /<FormattedMessage id="trinityCerttesol.sec11.month" />
                      </p>
                      <p className="c-text-20">
                        <FormattedMessage id="trinityCerttesol.sec11.course3" />
                      </p>
                    </Link>
                  )}
                </FormattedMessage>
              </div> */}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicCourses(
      filter: {
        data: { show_on_these_pages: { elemMatch: { page: { eq: "trinity-cert-tesol-course" } } } }
      }
      sort: { order: ASC, fields: data___from }
    ) {
      edges {
        node {
          id
          data {
            name {
              text
            }
            from(formatString: "D MMMM YYYY")
            to(formatString: "D MMMM YYYY")
            price
            is_price_per_month
            location
          }
        }
      }
    }
  }
`;

export default TrinityCertTesolCourse;
