import React from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '/src/components/LocalizedLink';

import { URL_KEYS_ROUTES_EN } from '../constants/routes';

import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';

function InternalSectionWithImage({ imgSrc = '', title = '' }) {
  return (
    <div className="internal-section-with-img container">
      <div className="row">
        <div className="col">
          <h2 className="internal-section-with-img__title c-title-34">{title}</h2>
          <div className="wrapper">
            <img className="big-img" src={imgSrc} alt={title} width={600} />
            <FormattedMessage id={URL_KEYS_ROUTES_EN.APPLY}>
              {(locale) => (
                <Link to={locale[0]} className="c-btn c-btn--red">
                  Book your course now
                  <IconArrowRight />
                </Link>
              )}
            </FormattedMessage>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InternalSectionWithImage;
